
.NoteListNav__list {
  list-style-type: none;
  margin: 130px 0 0;
  padding: 0;
}

.NoteListNav__button-wrapper {
  margin: 0 0 0 auto;
  max-width: 180px;
}

.NoteListNav__add-folder-button {
  margin: 20px auto;
}
.NoteListNav__add-folder-button:hover {
  background-color: #29862e;
  color: white;
}

.NoteListNav__folder-link {
  /* background-color: #1b1b1b65; */
  color: black;
  display: block;
  font-family: var(--custom-font);
  font-size: 15px;
  margin: auto;
  min-width: fit-content;
font-weight: 600;
  padding: 16px 16px;
  text-align: center;
  border-bottom: 1px ridge rgb(79, 79, 79);
  text-decoration: none;
}
.NoteListNav__folder-link:hover {
  color: #FFB72F;
  cursor: pointer;
}
.NoteListNav__folder-link.active {
  background-color:#FFB72F;
  color:rgb(0, 0, 0);
  opacity:100;
  
}

.NoteListNav__num-notes {
  color: #111111;
  display: block;
  float: right;
  font-size: 10px;
}
@media (min-width:760px){
  .NoteListNav__folder-link {
    width:auto;
    font-size: 25px;

  }
}