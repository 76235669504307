
.App {
  --custom-font: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: grid;
  grid-template-areas: "sidebar header" "sidebar main";
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 120px 1fr;
  min-height: 100vh;
}
.header-icon{
  color:black
}
.App__nav {
  background-color: #f2f2f2;
  
  grid-area: sidebar;
  height: 100vh;
  overflow: auto;
  /* box-shadow: 0px 0px 5px 5px rgba(10, 10, 10, 0.486) inset */
}

.App__header {
  background-color: #FFB72F;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.596);

  color: #bdbdbd;
  font-family: var(--custom-font);
  grid-area: header;
  padding-left: 16px;
  padding-top: 32px;
  text-shadow: 2px 1px 2px rgb(0, 0, 0);
  
}

.App__header a {
  color: #ffffff;
}

.App__main {
  background-color: #ffffff;
  grid-area: main;
  height: calc(100vh - 120px);
  overflow: auto;
  padding:25px;
  box-shadow: 0px 10px 10px grey inset;

}
@media (min-width:760px){
  .App__nav{
    /* box-shadow: 0px 0px 30px 10px rgba(10, 10, 10, 0.76) inset */

  }
}