
.NotePageMain {
  background-color: #ffffff;
  height: 100%;
  padding: 2px;
  width: 100%;
}

.NotePageMain__content {
  color: #000000;
  width: auto;
  padding:10px;
  font-size: .9em;
}
@media (min-width:760px){
  .NotePageMain__content {
    color: #000000;
    width: auto;
    padding:2em;
    font-size: 1.2em;
  }
  .NotePageMain {
  
    padding: 10px;

  }
}