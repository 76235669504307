
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.NavCircleButton {
  background-color: #cecccc;
  border-radius: 30px;
  border: 0;
  color: black;
  display: block;
  font-size: 12px;
  font-weight: bold;
  height: 65px;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  width: 65px;

}

a.NavCircleButton {
  padding: 16px 8px 8px;
}

.NavCircleButton:hover {
  cursor: pointer;
}


.NoteListNav__list {
  list-style-type: none;
  margin: 130px 0 0;
  padding: 0;
}

.NoteListNav__button-wrapper {
  margin: 0 0 0 auto;
  max-width: 180px;
}

.NoteListNav__add-folder-button {
  margin: 20px auto;
}
.NoteListNav__add-folder-button:hover {
  background-color: #29862e;
  color: white;
}

.NoteListNav__folder-link {
  /* background-color: #1b1b1b65; */
  color: black;
  display: block;
  font-family: var(--custom-font);
  font-size: 15px;
  margin: auto;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
font-weight: 600;
  padding: 16px 16px;
  text-align: center;
  border-bottom: 1px ridge rgb(79, 79, 79);
  text-decoration: none;
}
.NoteListNav__folder-link:hover {
  color: #FFB72F;
  cursor: pointer;
}
.NoteListNav__folder-link.active {
  background-color:#FFB72F;
  color:rgb(0, 0, 0);
  opacity:100;
  
}

.NoteListNav__num-notes {
  color: #111111;
  display: block;
  float: right;
  font-size: 10px;
}
@media (min-width:760px){
  .NoteListNav__folder-link {
    width:auto;
    font-size: 25px;

  }
}

.NotePageNav__back-button {
  margin: 45px 20px 25px auto
}
.NotePageNav__back-button:hover {
  background-color: #FFB72F;
}

.NotePageNav__folder-name {
  color: #2a2a2a;
  font-family: var(--custom-font);
  font-size: 35px;
  height: 50vh;
  margin:0 0 0 auto;
  text-orientation: upright;
  width: 75px;
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr;
}


.Note {
  --note-height: 88px;
  background-color: #ffffff;
  color: #131313;
  height: var(--note-height);
  margin-bottom: 8px;
  text-align: center;
  max-width: 380px;
  padding: 8px 0 8px 8px;
  position: relative;
  box-shadow:    3px 3px 3px 3px rgba(0, 0, 0, 0.295) ;
}

.Note__delete {
  --note-button-height: 32px;
  background-color: #103366;
  border: 0;
  color: #bdbcbc;
  float: right;
  font-size: 12px;
  height: var(--note-button-height);
  position: relative;
  top: calc((var(--note-button-height) + 32px) * -1);
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  width: calc(var(--note-height));
}
.Note__delete:hover {
  background-color: #bd0909;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.Note__title {
  font-family: var(--custom-font);
  font-size: 20px;
  margin: 0;
  max-width: 80%;
  min-height: 24px;
}
.Note__title a {
  color: #000000;
  display: block;
  text-decoration: none;
}
.Note__title a:hover {
  color: #FFB72F;
}

.Note__dates {
  bottom: 8px;
  color: #a7a5a5;
  font-size: 14px;
  position: absolute;
}


.NoteListMain ul {
  list-style-type: none;
  margin: 0;
  padding: 10px;
}

.NoteListMain__button-container {
  max-width: 380px;
  margin: 0 10px;
}

.NoteListMain__add-note-button {
  margin: 0 auto 20px;
}
.NoteListMain__add-note-button:hover {
  background-color: #29862e;
  color: white;
}
.NoteListMain_del-folder-button:hover{
  background-color:red;
  color:white
}
.NoteListMain_del-folder-button{
  position: relative;
float:right;
}


.NotePageMain {
  background-color: #ffffff;
  height: 100%;
  padding: 2px;
  width: 100%;
}

.NotePageMain__content {
  color: #000000;
  width: auto;
  padding:10px;
  font-size: .9em;
}
@media (min-width:760px){
  .NotePageMain__content {
    color: #000000;
    width: auto;
    padding:2em;
    font-size: 1.2em;
  }
  .NotePageMain {
  
    padding: 10px;

  }
}
.Noteful-form {
  background-color: rgb(214, 156, 49);
  color: black;
  padding: 16px;
  max-width: 332px;
}

.Noteful-form label {
  display: block;
  font-size: 16px;
  padding-bottom: 8px;
}

.Noteful-form input,
.Noteful-form textarea,
.Noteful-form select {
  font-size: 14px;
  padding: 8px;
  width: 300px;
}

.Noteful-form select {
  height: 33px;
}

.Noteful-form > .field {
  margin-bottom: 16px;
}
.Noteful-form > .buttons {
  margin-top: 26px;
}

.Noteful-form button {
  background-color: #010d1e;
  border-radius: 4px;
  border: 0;
  color: #cacaca;
  display: block;
  font-size: 18px;
  font-weight: bold;
  padding: 8px;
  text-align: center;
  text-decoration: none;
}
.Noteful-form button[type="submit"]:hover {
  background-color: #29862e;
  color: white;
}


.AddFolder {
  color: #5e5c5c;
}

.AddFolder h2 {
  margin-left: 16px;
}


.AddNote {
  color: #dedede;
}

.AddNote h2 {
  margin-left: 16px;
  color:rgb(78, 78, 78)
}


.App {
  --custom-font: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: grid;
  grid-template-areas: "sidebar header" "sidebar main";
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 120px 1fr;
  min-height: 100vh;
}
.header-icon{
  color:black
}
.App__nav {
  background-color: #f2f2f2;
  
  grid-area: sidebar;
  height: 100vh;
  overflow: auto;
  /* box-shadow: 0px 0px 5px 5px rgba(10, 10, 10, 0.486) inset */
}

.App__header {
  background-color: #FFB72F;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.596);

  color: #bdbdbd;
  font-family: var(--custom-font);
  grid-area: header;
  padding-left: 16px;
  padding-top: 32px;
  text-shadow: 2px 1px 2px rgb(0, 0, 0);
  
}

.App__header a {
  color: #ffffff;
}

.App__main {
  background-color: #ffffff;
  grid-area: main;
  height: calc(100vh - 120px);
  overflow: auto;
  padding:25px;
  box-shadow: 0px 10px 10px grey inset;

}
@media (min-width:760px){
  .App__nav{
    /* box-shadow: 0px 0px 30px 10px rgba(10, 10, 10, 0.76) inset */

  }
}
